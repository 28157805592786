<template>
  <div class="infoIndex123">
    <div class="hotbox flex_center">
      <div
        class="bigbig flex_center"
        @mouseenter="changeLeft"
        @mouseleave="changeLeftBack"
      >
        <div class="hotbox_left">
          <div>热门领域</div>
          <p
            v-for="(item, i) in LawKnowledgelist"
            :key="i"
            :class="[hottype == i ? 'active' : '']"
            @mouseenter="changeChan(i, item.id, item.name)"
          >
            <img :src="item.photo" alt="" />
            {{ item.name }}
          </p>
        </div>
        <div class="hotbox_center">
        
          <div v-if="!ishotleft">
            <el-carousel  height="436px">
              <el-carousel-item v-for="(data,num) in swipers" :key="num">
                  <img  :src="data.image"  alt=""  class="bgimg">
              </el-carousel-item>
            </el-carousel>
          </div>

          <div class="hotbox_center_main" v-else>
            <p class="ly_title">{{ hottitle }}</p>
            <div class="sapnbox">
              <a
                href="javascript:;"
                v-for="(item, i) in hotlist"
                :key="i"
                @click.stop="
                  tourllydetails(item)
                "
                >{{ item.name }}</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="hotbox_right">
        <div class="btnbox zixun" @click.stop="tourl('/toask')">快速咨询</div>
        <img src="@/assets/img/bgright.png" alt="" class="bgright" />
      </div>
    </div>

    <!-- 文章 -->
    <div class="infoartic flex base_w">
      <div class="common_index_leftwidth">
        <div class="newart">
          <div class="common_title_box">
            <span class="title">最新文章</span>
          </div>
          <newstu :list="innewlist"></newstu>
        </div>
        <div class="jctjbox">
          <div class="common_title_box">
            <span class="title">精彩推荐</span>
          </div>
          <div class="tjbox flexbox">
            <div
              class="tjitem"
              v-for="(item, i) in jingcailist"
              :key="i"
              @click.stop="tourl('infodetails?id=' + item.id)"
            >
              <img
                :src="item.photo"
                alt="" v-if="item.photo"
              />
              <img src="@/assets/img/404.png" alt="" v-else>
              <div class="boss1 line1">{{ item.content | filtersText }}</div>
            </div>
          </div>
          <newstu :more="true" :list="jingcailist2"></newstu>
        </div>
      </div>
      <div class="common_index_rightwidth">
        <!-- 热门文章 -->
        <hotlist :list="inhighlist"></hotlist>

        <!-- 优秀律师推荐  -->
        <div class="rmls">
          <div class="common_title_box">
            <span class="title">优秀律师推荐</span>
          </div>
          <hotls :list="Lawlist"></hotls>
        </div>
        <!-- 最新咨询 -->
        <div class="newzixun">
          <div class="common_title_box">
            <span class="title">最新咨询</span>
          </div>
          <div class="newziitem">
            <wenda :type="3" :wendalistArr="wentypedalist"></wenda>
            <div class="btnbox" @click.stop="tourl('/toask')">我要提问</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import newstu from '@/components/newstu'
import hotls from '@/components/hotls'
import wenda from '@/components/wenda'
import hotlist from '@/components/hotlist'

import apiUrl from '@/api/main'
export default {
  components: { newstu, hotls, wenda, hotlist },
  data() {
    return {
      hottype: '',
      ishotleft: false, // 鼠标是否在左边
      LawKnowledgelist: [], //热门领域
      hotlist: [], // 热门领域子元素
      hottitle: '', // 子元素标题

      innewlist: [], // 最新
      inhighlist: [], // 浏览量高
      jingcailist: [], // 精彩推荐文章
      jingcailist2: [],

      wentypedalist: [], // 问答列表
      Lawlist: [], // 律师列表
      swipers:[], // 轮播图
    }
  },
  filters: {
    // 富文本转文本
    filtersText(val) {
      if (val != null && val != '') {
        let reg = /[\u4e00-\u9fa5]/g
        let names = val.match(reg)
        val = names ? names.join('') : ''
        return val
      } else return ''
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getlsLunbolist(); // 轮播图
    this.getlsLawKnowledgelist() // 热门领域
    this.getnewlist() // 最新文章
    this.getHighlist() // 浏览量高文章
    this.getjingcailist() // 精彩
    this.gettypewendalist() // 问答
    this.lsLawyerlist() // l律师
    // 跳转回顶部
    document.documentElement.scrollTop = 0;
  },
  methods: {
    // 跳转
    tourl(url) {
      this.$router.push({
        path: url
      })
    },
    tourllydetails(item){
      if(!this.fuwuid && this.LawKnowledgelist.length > 0){
        this.fuwuid = this.LawKnowledgelist[0].id;
      }
      this.$router.push({
        path: '/lydetails?id=' + this.fuwuid + '&subid=' + item.id
      })
    },
    // 热门领域 鼠标移入
    changeChan(e, pId, hottitle) {
      console.log(e,pId,hottitle)

      this[`show${e}`] = true
      this.hottype = e
      this.gethostlist(pId)
      this.fuwuid = pId
      this.hottitle = hottitle
    },

    changeLeft() {
      this.ishotleft = true;
    },

    changeLeftBack() {
      this.ishotleft = false;
      this.hottype = ''
    },

    // 热门领域
    getlsLawKnowledgelist() {
      let data = {
        pageSize: 999,
        pageNum: 1,
        pId: 0,
        userId: localStorage.getItem('userId') || ''
      }
      apiUrl.lsLawKnowledgelist(data).then((res) => {
        this.LawKnowledgelist = res.rows;
        if(res.rows.length > 0){
          this.gethostlist(res.rows[0].id);
        }
      })
    },
    // 根据领域获取子元素
    gethostlist(pId) {
      let data = {
        pId: pId,
        userId: localStorage.getItem('userId') || ''
      }
      apiUrl.lsLawKnowledgelist(data).then((res) => {
        this.hotlist = res.rows;
      })
    },
    // 获取律师列表
    lsLawyerlist() {
      let data = {
        pageNum: 1,
        pageSize: 3,
        orderByColumn: ' ',
        isAsc: 'desc'
      }
      apiUrl.lsLawyerindex(data).then((res) => {
        res.rows.forEach((element) => {
          if (element.goodsIds) {
            element.goodsIdsArr = element.goodsIds.split(',')
          }
        })
        this.Lawlist = res.rows
      })
    },
    // 获取问答列表
    gettypewendalist() {
      let data = {
        pageSize: 2,
        pageNum: 1
      }
      apiUrl.wendalist(data).then((res) => {
        this.wentypedalist = res.rows
      })
    },
    // 最新文章
    getnewlist() {
      let data = {
        pageSize: 3,
        pageNum: 1,
        orderByColumn: 'createTime',
        isAsc: 'desc'
      }
      apiUrl.lsInformationlist(data).then((res) => {
        this.innewlist = res.rows
      })
    },
    // 精彩推荐 3
    getjingcailist() {
      let data = {
        pageSize: 12,
        pageNum: 1,
        orderByColumn: 'createTime',
        isAsc: 'desc'
      }
      apiUrl.lsInformationlist(data).then((res) => {
        res.rows.forEach((ele, i) => {
          if (i >= 3 && i <= 5) {
            this.jingcailist.push({
              id: ele.id,
              title: ele.title,
              content: ele.content,
              photo: ele.photo,
              createTime: ele.createTime
            })
          } else if (i >= 6) {
            this.jingcailist2.push({
              id: ele.id,
              title: ele.title,
              content: ele.content,
              photo: ele.photo,
              createTime: ele.createTime
            })
          }
        })
      })
    },
    // 浏览量高的文章
    getHighlist() {
      let data = {
        pageSize: 10,
        pageNum: 1,
        orderByColumn: 'browseNum',
        isAsc: 'desc'
      }
      apiUrl.lsInformationlist(data).then((res) => {
        this.inhighlist = res.rows
      })
    },
     // 获取轮播图
     getlsLunbolist() {
      apiUrl.lsLunbolist().then((res) => {
        if(res.rows.length > 0){
          res.rows.forEach(item => {
            this.swipers.push({
              image:item.photo,
              title:item.title
            })
          });

          this.swipers.push(this.swipers[0]);
          if(res.rows.length > 1){
            this.swipers.push(this.swipers[1]);
          }
        }
       
        
      })
    },
  }
}
</script>
<style lang='scss'>
.infoIndex123 {
// 热门领域
.hotbox{
  padding-top: 24px;
  padding-bottom: 24px;
  background: #F8F8F8;
  .hotbox_left{
    width:220px;
    height: 432px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px 0 25px;
    overflow: auto;

    div{
      // font-size: 17px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 23px;
      border-left: 7px solid #305BFE;
      // text-align: center;
      margin-bottom: 10px;
      padding-left: 48px;
    }
    p{
      // font-size: 13px;
      // line-height: 37px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 43px;
      display: flex;
      align-items: center;
      // justify-content: center;
      padding-left: 48px;
      cursor: pointer;
      img{
        // width:17px;
        // height: 17px;
        // margin-right: 8px;
        width:20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    .active{
      color: #305BFE;
      background: #E9EDFB;
    }
  }
  .hotbox_left::-webkit-scrollbar{
    display: none;
  }
  .hotbox_center{
    // width: 727px;
    // height: 363px;
    // margin: 0 13px;
    // border-radius: 8px;
    width: 872px;
    height: 436px;
    margin: 0 16px;
    border-radius: 10px;
    .bgimg{
      // width: 727px;
      // height: 363px;
      // border-radius: 8px;
      width: 872px;
      height: 436px;
      border-radius: 10px;
    }
    .hotbox_center_main{
      // width: 727px;
      // height: 363px;
      width: 872px;
      height: 436px;
      background-color: #fff;
      padding: 33px;
      box-shadow: 0px 2px 5px 0px rgba(51,63,109,0.24);
      border-radius: 8px;
      .ly_title{
        font-size: 17px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-bottom: 20px;
      }
      .sapnbox{
        a{
          border-radius: 17px;
          border: 1px solid #CCCCCC;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          padding: 8px 17px;
          margin-right: 13px;
          margin-bottom: 16px;
        }
        a:hover{
          border: 1px solid #305BFE;
          color: #305BFE;
          cursor: pointer;
        }
       
      }
    }
  }
  .hotbox_right{
    // width:263px;
    // height: 363px;
    width:316px;
    height: 436px;
    cursor: pointer;
    position: relative;

    .bgright{
      // width:263px;
      // height: 363px;
      width:316px;
      height: 436px;
    }
    >div{
      position: absolute;
      // width: 137px;
      // top: 135px;
      // height: 40px;
      // line-height: 40px;
      top: 162px;
      left: 0;
      right: 0;
      width: 164px;
      height: 48px;
      line-height: 48px;
      margin: 0 auto;
      font-size: 18px;
    }
  }

}

  .infoartic {
    padding-top: 40px;
    padding-bottom: 67px;
    // 最新文章
    .newart {
      border-bottom: 3px solid #333;
      padding-bottom: 33px;
    }
    // 精彩推荐
    .jctjbox {
      margin-top: 33px;
      .tjbox {
        margin-bottom: 20px;
        .tjitem {
          width: 266px;
          height: 240px;
          background: #f5f5f5;
          border-radius: 12px;
          margin-right: 16px;
          margin-top: 24px;
          img {
            width: 266px;
            height: 150px;
            border-radius: 10px 10px 0px 0px;
          }
          .boss1 {
            padding: 13px 10px;
            border-radius: 0px 0px 10px 10px;
            font-size: 16px;
          }
        }
        .tjitem:nth-child(3) {
          margin-right: 0px;
        }
      }
    }
  }

  // 优秀律师推荐
  .rmls {
    margin-top: 33px;
    .common_title_box {
      margin-bottom: 16px;
    }
  }

  // 最新咨询
  .newzixun {
    margin-top: 33px;
    .common_title_box {
      margin-bottom: 16px;
    }
    .newziitem {
      width: 586px;
      background: #f8f8f8;
      border-radius: 10px;
      padding: 0 27px 27px;
      .btnbox {
        width: 153px;
        height: 40px;
        line-height: 40px;
        border-radius: 30px;
        margin: 0 auto;
      }
    }
  }
}

.el-carousel__indicators{
  display: none !important;
}
</style>